<template>
    <v-container>
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <v-card>
            <v-card-title class="blue lighten-3">グラフ設定</v-card-title>
            <v-card-text>
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr class="text-center">
                                <th width="33%"></th>
                                <th width="33%">Min</th>
                                <th width="33%">Max</th>
                            </tr>
                            <tr class="text-center">
                                <td>水温(℃)</td>
                                <td><v-text-field type="number" v-model="chartRange.water.min" /></td>
                                <td><v-text-field type="number" v-model="chartRange.water.max" /></td>
                            </tr>
                            <tr class="text-center">
                                <td>塩分</td>
                                <td><v-text-field type="number" v-model="chartRange.salinity.min" /></td>
                                <td><v-text-field type="number" v-model="chartRange.salinity.max" /></td>
                            </tr>
                            <tr class="text-center">
                                <td>DO(mg/L)</td>
                                <td><v-text-field type="number" v-model="chartRange.oxygen.min" /></td>
                                <td><v-text-field type="number" v-model="chartRange.oxygen.max" /></td>
                            </tr>
                            <tr class="text-center">
                                <td>pH</td>
                                <td><v-text-field type="number" v-model="chartRange.ph.min" /></td>
                                <td><v-text-field type="number" v-model="chartRange.ph.max" /></td>
                            </tr>
                            <tr class="text-center">
                                <td>給餌量</td>
                                <td><v-text-field type="number" v-model="chartRange.feed.min" /></td>
                                <td><v-text-field type="number" v-model="chartRange.feed.max" /></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <v-divider class="ma-3"></v-divider>
        <v-btn block color="success" @click="saveRange()">OK</v-btn>
        <!-- ============================================= -->
    </v-container>
</template>

<script>
export default {
    name: "ChartSetting",
    components: {},
    //========================================================
    data() {
        return {
            //--------------------------
            jwt: "",
            myInfo: [],
            isLoading: false,
            //--------------------------
            chartRange: {
                water: { min: 0, max: 0 },
                salinity: { min: 0, max: 0 },
                oxygen: { min: 0, max: 0 },
                ph: { min: 0, max: 0 },
                feed: { min: 0, max: 0 },
            },
            //--------------------------
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //-----------------------
        this.isLoading = true;
        //-----------------------
        await new Promise((r) => setTimeout(r, 1000));
        if (this.$localStorage.get("chart.range.json") != undefined) {
            Object.assign(this.chartRange, JSON.parse(this.$localStorage.get("chart.range.json")));
        }
        //-----------------------
        this.isLoading = false;
        //-----------------------
        window.scrollTo(0, 0);
    },
    //========================================================
    methods: {
        async saveRange() {
            this.isLoading = true;
            this.$localStorage.set("chart.range.json", JSON.stringify(this.chartRange));
            await new Promise((r) => setTimeout(r, 1000));
            this.isLoading = false;
        },
    },
    //========================================================
};
</script>
