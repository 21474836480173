import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import moment from "vue-moment";
import VueLocalStorage from "vue-localstorage";
import axios from "axios";
import VueAxios from "vue-axios";
import VueJWT from "vuejs-jwt";

Vue.use(moment);
Vue.use(VueLocalStorage);
Vue.use(VueAxios, axios);
Vue.use(VueJWT);

axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "/" : "https://isari.umilog.cloud";

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
