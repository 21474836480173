<template>
    <v-container>
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <h2>{{ dbDevice.name }}</h2>
        <v-divider class="mb-3"></v-divider>
        <!-- ============================================= -->
        <v-card>
            <v-card-title>
                <div style="width: 160px">
                    <v-select :items="monthList" v-model="targetMonth" @change="monthChange()" outlined hide-details dense></v-select>
                </div>
                <v-spacer></v-spacer>
                <v-btn class="ma-1" :to="{ name: 'DeviceChart', params: { device_id: device_id } }" color="success"><v-icon>mdi-chart-line</v-icon>グラフ</v-btn>
                <v-btn @click="editItemDialog(null)" color="primary">追加</v-btn>
            </v-card-title>
            <v-card class="pa-5">
                <v-data-table :headers="headers" hide-default-footer :items-per-page="-1" :items="dbFeededLogs" dense fixed-header :height="tableHeight" mobile-breakpoint="200" multi-sort>
                    <template v-slot:[`header.feeded_weight`]="{ header }">{{ header.text }} ({{ dbDevice.feed_unit }})</template>
                    <template v-slot:[`header.measured_at`]="{ header }">{{ header.text }}</template>

                    <template v-slot:[`item.edit-btn`]="{ item }">
                        <v-icon small class="mx-1" @click="editItemDialog(item)"> mdi-pencil </v-icon>
                    </template>
                    <template v-slot:[`item.delete-btn`]="{ item }">
                        <v-icon small class="mx-1" @click="deleteItemConfirmDialog(item)"> mdi-delete </v-icon>
                    </template>
                    <template v-slot:[`item.measured_at`]="{ item }">
                        <div>{{ $moment(item.measured_at).format("YYYY-MM-DD HH:mm") }}</div>
                    </template>
                    <template v-slot:[`item.feeded_weight`]="{ item }">
                        <div class="text-right">{{ item.feeded_weight }}</div>
                    </template>
                </v-data-table>
            </v-card>
        </v-card>
        <!-- =============================== -->
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-card>
                <v-card-title class="blue white--text">給餌量登録</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <v-row>
                        <!-- ----------------------------------------------------- -->
                        <v-col cols="6">
                            <v-dialog v-model="dispMonthDlg" :return-value.sync="editedItem.measured_date" persistent width="290px" ref="zeroDateDialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="editedItem.measured_date" label="給餌日" readonly v-bind="attrs" v-on="on" dense></v-text-field>
                                </template>
                                <v-date-picker v-model="editedItem.measured_date" locale="jp" :min="calendarMin" :max="calendarMax">
                                    <v-btn text color="primary" @click="dispMonthDlg = false"> Cancel </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="$refs.zeroDateDialog.save(editedItem.measured_date)"> OK </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <!-- ----------------------------------------------------- -->
                        <v-col cols="6">
                            <v-dialog v-model="dispClockDlg" :return-value.sync="editedItem.measured_time" persistent width="290px" ref="stimeDialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="editedItem.measured_time" label="給餌時刻" readonly v-bind="attrs" v-on="on" dense></v-text-field>
                                </template>
                                <v-time-picker v-if="dispClockDlg" v-model="editedItem.measured_time" full-width format="24hr">
                                    <v-btn text color="primary" @click="dispClockDlg = false"> Cancel </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="$refs.stimeDialog.save(editedItem.measured_time)"> OK </v-btn>
                                </v-time-picker>
                            </v-dialog>
                        </v-col>
                        <!-- ----------------------------------------------------- -->
                        <v-col cols="6">
                            <v-text-field v-model="editedItem.feeded_weight" :label="'給餌量(' + dbDevice.feed_unit + ')'" dense></v-text-field>
                        </v-col>
                        <!-- ----------------------------------------------------- -->
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="dialog = false" color="warning">Cancel</v-btn>
                            <v-btn @click="submitItem(editedItem)" color="success" class="float-right">OK</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- =============================== -->
        <v-dialog v-model="dialogDeleteConfirm" max-width="500px">
            <v-card>
                <v-card-title class="blue white--text">削除確認</v-card-title>
                <v-card-text class="text-h5">
                    <div>【給餌日時】{{ editedItem.measured_at }}</div>
                    <div>【給餌量】{{ editedItem.feeded_weight }}</div>
                    <div class="ml-5">を削除しますか?</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" @click="dialogDeleteConfirm = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="submitDeleteItem(editedItem)">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- =============================== -->
    </v-container>
</template>

<style>
table {
    border-collapse: collapse;
}
table thead tr th {
    color: #fff !important;
    background: #41a2e6 !important;
    border: thin solid #ddd;
}
table tbody tr td {
    border: thin solid #ddd;
}
.number-input input {
    text-align: right;
}
</style>

<script>
export default {
    name: "FeededList",
    components: {},
    //#######################################################
    data: function () {
        return {
            jwt: "",
            myInfo: [],
            device_id: undefined,
            mac: "",
            dbDevice: {},
            dbFeededLogs: [],
            targetMonth: this.$moment().format("YYYY-MM"),
            monthList: [
                { value: "2024-10", text: "2024年10月" },
                { value: "2024-09", text: "2024年9月" },
                { value: "2024-08", text: "2024年8月" },
            ],
            today: this.$moment().format("YYYY-MM-DD"),
            calendarMin: this.$moment().format("YYYY-MM-01"),
            calendarMax: this.$moment().format("YYYY-MM-DD"),
            //-------------------------
            headers: [
                { text: "編集", value: "edit-btn", sortable: false, width: 50 },
                { text: "給餌日時", value: "measured_at" },
                { text: "給餌量", value: "feeded_weight" },
                { text: "削除", value: "delete-btn", sortable: false, width: 50 },
            ],
            footerProps: {
                itemsPerPageOptions: [100, 200, 300, -1],
            },
            itemsPerPage: 100,
            //-------------------------
            dialog: false,
            dialogDeleteConfirm: false,
            editedIndex: -1,
            editedItem: {
                measured_at: this.$moment().format("YYYY-MM-DD HH:mm"),
                measured_date: this.$moment().format("YYYY-MM-DD"),
                measured_time: this.$moment().format("HH:mm"),
            },

            dispMonthDlg: false,
            dispClockDlg: false,
            //-------------------------
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            isLoading: false,
            //-------------------------
        };
    },
    //#######################################################
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------------------------
        this.device_id = this.$route.params.device_id;
        if (this.$route.query.targetMonth) this.targetMonth = this.$route.query.targetMonth;
        //---------------------------------------------
        //-----------------------
        this.isLoading = true;
        //-----------------------
        // 非同期でAxiosGet
        const promiseDevice = this.getDevices();
        const promiseFeeded = this.getFeededLogs();
        this.createMonthList();
        //-----------------------
        // 上記の終了を待つ
        await promiseDevice;
        await promiseFeeded;
        //-----------------------
        // 上記が全て取得終了したタイミングで、配列マージする
        // await new Promise((r) => setTimeout(r, 500));
        // await this.buildDeviceTree();
        //-----------------------
        await new Promise((r) => setTimeout(r, 500));
        this.isLoading = false;
        //-----------------------
        window.scrollTo(0, 0);
    },
    //#######################################################
    mounted: function () {
        window.addEventListener("resize", this.handleResize);
    },
    //#######################################################
    beforeDestroy: function () {
        window.removeEventListener("resize", this.handleResize);
    },
    //#######################################################
    computed: {
        tableHeight: function () {
            let ret = 300;
            ret = this.windowHeight - 270;
            if (ret < 300) ret = 300;
            return ret;
        },
    },
    //#######################################################
    methods: {
        //====================================================
        createMonthList() {
            this.monthList = [];
            for (let i = 0; i < 12 * 5; i++) {
                let val = this.$moment().add(-i, "month").format("YYYY-MM");
                let txt = this.$moment().add(-i, "month").format("YYYY年MM月");
                this.monthList.push({ value: val, text: txt });
            }
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id,
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                if (response.data.json[0]) {
                    this.dbDevice = response.data.json[0];
                    this.mac = this.dbDevice["mac"];
                }
            });
        },
        //====================================================
        async getFeededLogs() {
            //--------------------------------
            const sdate = this.$moment(this.targetMonth + "-01")
                .add(0, "days")
                .format("YYYY-MM-DD");
            const edate = this.$moment(this.targetMonth + "-01")
                .add(1, "month")
                .format("YYYY-MM-DD");
            //--------------------------------
            let requestURL = "/web/api/feeded_logs";
            const params = {
                device_id: this.device_id,
                "measured_at[EQGREAT]": sdate,
                "measured_at[SMALL]": edate,
                // _fields: "measured_at,feeded_weight",
                "_order[measured_at]": "asc",
            };
            await this.axios({
                method: "GET",
                url: requestURL,
                params: params,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbFeededLogs = response.data.json;
                //-----------------
                for (let idx in this.dbFeededLogs) {
                    let measured_at = this.dbFeededLogs[idx]["measured_at"];
                    this.dbFeededLogs[idx]["measured_at"] = this.$moment(measured_at).format("YYYY-MM-DD HH:mm");
                }
            });
            //--------------------------------
        },
        //====================================================
        async monthChange() {
            this.calendarMin = this.$moment(this.targetMonth + "-01").format("YYYY-MM-DD");
            this.calendarMax = this.$moment(this.calendarMin).add(1, "month").add(-1, "days").format("YYYY-MM-DD");
            if (this.calendarMax > this.today) this.calendarMax = this.today;
            //------------------------------
            this.isLoading = true;
            await new Promise((r) => setTimeout(r, 500));
            this.getFeededLogs();
            this.isLoading = false;
        },
        //====================================================
        editItemDialog(item) {
            if (!item) {
                const defDate = this.$moment(this.targetMonth + "-01").format("YYYY-MM-DD HH:mm");
                this.editedIndex = -1;
                this.editedItem = {
                    id: 0,
                    device_id: this.device_id,
                    feeded_key: "",
                    feeded_weight: 0,
                    measured_at: this.$moment(defDate).format("YYYY-MM-DD HH:mm"),
                    measured_date: this.$moment(defDate).format("YYYY-MM-DD"),
                    measured_time: this.$moment(defDate).format("HH:mm"),
                    modified_at: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
                    mac: this.mac,
                };
            } else {
                this.editedIndex = this.dbFeededLogs.indexOf(item);
                // this.editedItem = Object.assign({}, item); // カレンダーのヘッダ部分が、日付クリックしても変わらなくなるのでダメ
                this.editedItem.id = item.id;
                this.editedItem.device_id = item.device_id;
                this.editedItem.feeded_key = item.feeded_key;
                this.editedItem.feeded_weight = item.feeded_weight;
                this.editedItem.mac = item.mac;
                this.editedItem.measured_at = item.measured_at;
                let measured_at = item.measured_at;
                this.editedItem.measured_date = this.$moment(measured_at).format("YYYY-MM-DD");
                this.editedItem.measured_time = this.$moment(measured_at).format("HH:mm");
            }
            this.dialog = true;
        },
        //====================================================
        async submitItem(item) {
            let ErrCount = 0;
            if (isNaN(Number(item.feeded_weight))) ErrCount++;
            if (ErrCount) {
                alert("給餌量が不正です。");
                return false;
            }
            //-----------------------------------
            let axiosData = Object.assign({}, item);
            axiosData.measured_at = this.$moment(axiosData.measured_date + " " + axiosData.measured_time).format("YYYY-MM-DD HH:mm");
            axiosData.modified_at = this.$moment().format("YYYY-MM-DD HH:mm:ss");
            axiosData.feeded_weight = String(Number(axiosData.feeded_weight));
            delete axiosData.id;
            delete axiosData.measured_date;
            delete axiosData.measured_time;

            //-----------------------------------
            let restMethod = "POST";
            let restUrl = "/web/api/feeded_logs";
            if (item.id > 0) restMethod = "PUT";
            if (item.id > 0) restUrl = restUrl + "/" + item.id;
            //-----------------------------------
            await this.axios({
                method: restMethod,
                url: restUrl,
                headers: { Authorization: "Bearer " + this.jwt },
                data: axiosData,
                timeout: 30000,
            })
                .then((response) => {
                    if (response.data.json) {
                        //self.console.log(response.data.json);
                        this.dialog = false;
                        this.getFeededLogs();
                    }
                })
                .catch(function (error) {
                    if (error.response.data.err == "ER_DUP_ENTRY") {
                        alert("IDが重複しています");
                    }
                });
        },
        //====================================================
        deleteItemConfirmDialog(item) {
            this.editedIndex = this.dbFeededLogs.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDeleteConfirm = true;
        },
        //====================================================
        async submitDeleteItem(item) {
            this.isLoading = true;
            const PrimaryKey = item.id;
            await this.axios({
                method: "DELETE",
                url: "/web/api/feeded_logs/" + PrimaryKey,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then(() => {
                this.getFeededLogs();
                this.dialogDeleteConfirm = false;
            });
            this.isLoading = false;
        },
        //====================================================
    },
    //#######################################################
};
</script>
