<script>
import { Bar, mixins } from "vue-chartjs";

export default {
    extends: [Bar],
    mixins: [Bar, mixins.reactiveData],
    //====================================================================
    props: {
        //chartOptions: {},
        chartDataChild: {},
        yRange: { min: Number, max: Number },
    },
    //====================================================================
    data() {
        return {
            //ymax: "15",
        };
    },
    //====================================================================
    created: function () {
        //this.buildOption();
    },
    //====================================================================
    mounted() {
        //this.renderChart(this.chartDataChild, this.chartOptions);
    },
    //====================================================================
    watch: {
        chartDataChild: async function () {
            await this.buildOption();
            await this.renderChart(this.chartDataChild, this.chartOptions);
        },
    },
    //====================================================================
    methods: {
        buildOption() {
            this.chartOptions = {
                maintainAspectRatio: false,
                legend: { display: true, position: "bottom" },
                scales: {
                    xAxes: [
                        {
                            type: "time",
                            distribution: "linear", // use 'linear'(default) or 'series'
                            time: { displayFormats: { hour: "D日H時", day: "D日H時", month: "D日H時" } },
                            ticks: { minRotation: 0, maxRotation: 0, maxTicksLimit: 4 },
                            gridLines: { drawOnChartArea: true },
                            stacked: true,
                            //barPercentage: 1.2,
                            //categoryPercentage: 1.0,
                        },
                    ],
                    yAxes: [
                        {
                            display: true,
                            position: "left",
                            scaleLabel: { display: false, labelString: "Kg", fontColor: "black" },
                            ticks: {
                                fontColor: "navy",
                                min: Number(this.yRange.min) ? Number(this.yRange.min) : 0,
                                max: Number(this.yRange.max) ? Number(this.yRange.max) : undefined,
                            },
                            stacked: true,
                        },
                    ],
                },
                tooltips: {
                    enabled: true,
                },
                animation: false,
            };
        },
    },
    //====================================================================
};
</script>
