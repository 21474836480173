import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
//---------------------------------------------------
import DevicesList from "../views/DevicesList.vue";
import FeededList from "../views/FeededList.vue";
import DeviceChart from "../views/DeviceChart.vue";
import Settings from "../views/Settings.vue";
import Favorites from "../views/Favorites.vue";
import ChartSetting from "../views/ChartSetting.vue";
//---------------------------------------------------

Vue.use(VueRouter);

const routes = [
    {
        name: "Login",
        path: "/login",
        meta: { isPublic: true },
        component: Login,
    },
    {
        name: "Logout",
        path: "/logout",
        meta: { isPublic: true },
        component: Logout,
    },
    // {
    //     path: "/home",
    //     name: "Home",
    //     component: Home,
    // },
    {
        path: "/",
        name: "DevicesList",
        component: DevicesList,
    },
    {
        name: "DevicesList",
        path: "/devices-list",
        component: DevicesList,
    },
    {
        name: "FeededList",
        path: "/feeded-list",
        component: FeededList,
    },
    {
        name: "DeviceChart",
        path: "/device-chart/:device_id/",
        component: DeviceChart,
    },
    {
        name: "Settings",
        path: "/settings",
        component: Settings,
    },
    {
        name: "Favorites",
        path: "/favorites",
        component: Favorites,
    },
    {
        name: "ChartSetting",
        path: "/chart-setting",
        component: ChartSetting,
    },
    // {
    //     path: "/about",
    //     name: "About",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
    // },
];

const router = new VueRouter({
    routes,
});

export default router;
